import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { get_all_subscribers } from "../Configuration/Api";




function AllSubscribers() {

    const [data, setData] = useState([]);

    const getData = async () => {
        try {
            const res = await get_all_subscribers();
            if (res?.success) {
                setData(res?.data)
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong"
                })
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3 style={{
                backgroundColor: "grey",
                color: "white",
            }}>All Subscriber List </h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Subscribe  Date</th>
                        <th className="text-right">User Email</th>

                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border">
                            <td>{index + 1}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{item.subject}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.city}</td>
                            <td>{item.status}</td>
                            <td>{item.request}</td>
                            <td>{item.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AllSubscribers