import config from "../Configuration/config.json";
import axios from "axios";
const ApiCall = async ({ route, body, method, token }) => {
  console.log("body is coming", body);
  try {
    if (!route) {
      return;
    }
    const url = `${config.server}/${route}`;
    var response;
    console.log("dasd", url, config, body);
    console.log("body is coming", body);
    switch (method) {
      case "post-form":
        console.log("body is coming", body);
        response = await axios.post(url, body, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authentication: token,
          },
        });
        break;
    }

    if (response) {
      return response;
    } else {
      return {
        status: 400,
        error: "Error into api calling" + route,
      };
    }
  } catch (err) {
    return {
      status: 400,
      error: err,
    };
  }
};

export { ApiCall };

// import { BASE_URL } from "../Constants";
// import axios from "axios";
// export const ApiCall = async ({ params, route, verb, token, baseurl }) => {
//   try {
//     let url = null;
//     if (baseurl == false) {
//       url = route;
//     } else {
//       url = `${BASE_URL}/${route}`;
//     }
//     // console.log("url", url);
//     // console.log("verb", verb);
//     // console.log("token", token);
//     // console.log("params", params);

//     let response = null;
//     console.log("URLLLLLL",url)
//     switch (verb) {
//       case "get":
//         response = await axios.get(
//           url,

//           token ? { headers: { "x-sh-auth": token } } : null
//         );
//         break;
//       case "get2":
//         response = await axios.get(
//           url,
//           { params },
//           token ? { headers: { "x-access-token": token } } : null
//         );
//         break;
//       case "post":
//         response = await axios.post(
//           url,
//           params,

//           token ? { headers: { "x-sh-auth": token } } : null
//         );
//         break;

//       case "put":
//         response = await axios.put(
//           url,
//           params,

//           token ? { headers: { "x-sh-auth": token } } : null
//         );
//         break;
//       case "patch":
//         response = await axios.patch(
//           url,
//           params,
//           token ? { headers: { "x-sh-auth": token } } : null
//         );
//         break;
//       case "delete":
//         response = await axios.delete(
//           url,
//           token ? { headers: { "x-sh-auth": token } } : null,
//           params
//         );
//         break;

//       default:
//         return { code: "400", response: "method not found" };
//         break;
//     }
//     if (response) {
//       return await { status: 200, response: response.data };
//     } else {
//       return response;
//     }
//   } catch (e) {
//     console.log("response: ", e.response);
//     console.log(e.response?.status);
//     if (e.response?.status == 401 && token && token?.trim() !== "") {
//       localStorage.clear();
//       window.location.pathname = "/";
//     }
//     return {
//       status: 400,
//       response: e?.response?.data
//         ? e?.response?.data
//         : { message: e.toString() },
//     };
//   }
// };
