



import React, { useEffect, useState } from 'react';
import { get_analytics } from '../../Configuration/Api';
import Swal from 'sweetalert2';

function Home() {


    const [data, setData] = useState([]);
    const getData = async () => {
        const res = await get_analytics();
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])
    
    return (
        <div className="container mt-5">
            {/* First Row */}
            <div className="row">
                {/* First Box */}
                <div className="col-md-6 mb-4">
                    <div
                        className="border border-dark p-3"
                        style={{
                            backgroundColor: "#f8d7da",
                            color: "#721c24",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <h2>Orders</h2>
                        <div style={{ fontSize: "18px", margin: "10px 0" }}>
                            <p>Total Orders: <span style={{ fontWeight: "bold" }}>{data?.totalOrder}</span></p>
                            <p>New Orders: <span style={{ fontWeight: "bold" }}>{data?.newOrder}</span></p>
                        </div>
                    </div>
                </div>

                {/* Second Box */}
                <div className="col-md-6 mb-4">
                    <div
                        className="border border-dark p-3"
                        style={{
                            backgroundColor: "#d4edda",
                            color: "#155724",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <h2>Requests</h2>
                        <div style={{ fontSize: "18px", margin: "10px 0" }}>
                            <p>Total Requests: <span style={{ fontWeight: "bold" }}>{data?.totalRequest}</span></p>
                            <p>New Request: <span style={{ fontWeight: "bold" }}>{data?.newRequest}</span></p>
                        </div>
                    </div>
                </div>
            </div>

             {/* second Row */}
             <div className="row">
                {/* First Box */}
                <div className="col-md-6 mb-4">
                    <div
                        className="border border-dark p-3"
                        style={{
                            backgroundColor: "#FF7F7F",
                            color: "#721c24",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <h2>Products</h2>
                        <div style={{ fontSize: "18px", margin: "10px 0" }}>
                            <p>Total Products: <span style={{ fontWeight: "bold" }}>{data?.totalProducts}</span></p>
                        </div>
                    </div>
                </div>

                {/* Second Box */}
                <div className="col-md-6 mb-4">
                    <div
                        className="border border-dark p-3"
                        style={{
                            backgroundColor: "#eee",
                            color: "#155724",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <h2>Categories</h2>
                        <div style={{ fontSize: "18px", margin: "10px 0" }}>
                            <p>Total Orders: <span style={{ fontWeight: "bold" }}>{data?.totalCategories}</span></p>
                        </div>
                    </div>
                </div>
            </div>


            {/* Second Row */}
            <div className="row">
                {/* Third Box */}
                <div className="col-md-6 offset-md-3 mb-4">
                    <div
                        className="border border-dark p-3"
                        style={{
                            backgroundColor: "#cce5ff",
                            color: "#004085",
                            borderRadius: "8px",
                            textAlign: "center",
                        }}
                    >
                        <h2>Subscribers</h2>
                        <div style={{ fontSize: "18px", margin: "10px 0" }}>
                            <p>Total Orders: <span style={{ fontWeight: "bold" }}>{data?.totalSubscribers}</span></p>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
