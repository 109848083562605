import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { get_products } from "../../Configuration/Api";
import { useNavigate } from "react-router-dom";



function AllProducts({ changeView }) {

    const [data, setData] = useState([]);

    const navigate = useNavigate();
    const getData = async () => {
        const res = await get_products("all");
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const goToSingleOrder = (id) => {
        navigate(`?id=${id}`)
        changeView("Single_Order")
    }

    return (
        <div>
            <h3 style={{
                backgroundColor: "tomato",
                color: "white",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Products</h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Product ID</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Discount</th>
                        <th>Status</th>
                        <th>CreatedBy</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border"
                        >
                            <td>
                                <img src={item?.images?.[0]?.Location} alt={"sa"} style={{ width: '150px', height: "150px", marginRight: '5px' }} />
                            </td>
                            <td>{item._id.toString().slice(-5)}</td>
                            <td>{item?.name}</td>
                            <td>{item?.price}</td>
                            <td>{item?.category}</td>
                            <td>{item?.discount}%</td>
                            <td>{item.status}</td>
                            <td >{item?.createdBy?.first_name}</td>
                            <td >
                                <p
                                    // onClick={() => goToSingleOrder(item._id)}
                                    className="p-2 text-center text-white cursor-pointer mt-2" style={{ backgroundColor: "tomato", cursor: "pointer" }} >
                                    Update
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AllProducts