import React from "react";
import logo from "../../Images/logo.png"

function Header() {

    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid ">
                <a class="navbar-brand" href="#">
                    <img src={logo} alt="Hanis Logo" height={100} width={150} />
                </a>

                <button
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-bs-target="#navbarNavDropdown"
                    data-bs-toggle="collapse"
                    type="button"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navbarNavDropdown"
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a
                                aria-current="page"
                                className="nav-link active"
                                href="/"
                            >
                                Log out
                            </a>
                        </li>


                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header