import React from 'react'
import { useForm } from 'react-hook-form'
import "./Product.scss"
import 'bootstrap-icons/font/bootstrap-icons.css';
import Swal from 'sweetalert2';
import { createSubCategory } from '../../Configuration/Api';
function AddCategories() {
    const { register, handleSubmit } = useForm();
    // here we are submitting the forms
    const onSubmit = async (data) => {
        if (!data) {
            Swal.fire({
                icon: "error",
                title: "Please fill the form first!"
            })
        }
        // call the api of creating the sub category of product
        try {
            const response = await createSubCategory(data)
            console.log("response", response);
            if (response?.success) {
                Swal.fire({
                    icon: "success",
                    title: "Sub Category Added"
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Something went wrong!",
                    text: "Sub Category is aleady register or please try again later"
                })
            }

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Something went wrong!",
                text: "Sub Category is aleady register or please try again later"
            })
        }
    }

    return (
        <div>
            <h3 style={{
                backgroundColor: "red",
                color: "white"
            }}>Added New Category</h3>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className="label text-center">Sub Category Name</label>
                    <input className="input" type="text" {...register("name", { required: true })} required placeholder="Mayo" />
                </div>
                <input type="submit" value="Add" className="submit" />

            </form>
        </div>
    )
}

export default AddCategories
