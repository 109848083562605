import React, { useEffect, useState } from "react";
import { get_all_contact_request } from "../Configuration/Api";
import Swal from "sweetalert2";



function Request() {

    const [data, setData] = useState([]);

    const getData = async () => {
        const res = await get_all_contact_request();
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <h3 style={{
                backgroundColor:"pink",
                color: "black",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Users Reqeusts</h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>City</th>
                        <th>Status</th>
                        <th>Request</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border" style={{
                            // backgroundColor: item.request == "Contact" ? "green" : "red",
                            // color:"white"
                        }}>
                            <td>{index + 1}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{item.subject}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.city}</td>
                            <td>{item.status}</td>
                            <td >{item.request}</td>
                            <td>{item.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Request