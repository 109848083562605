import React from 'react';

const OrderDetails = ({ order }) => {
    const {
        billing_details,
        total_amount,
        status,
        order_details,
        createdAt,
        updatedAt,
    } = order;

    return (
        <div className="container mt-5">
            {/* Customer Details */}
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Customer Details</h4>
                </div>
                <div className="card-body">
                    <p><strong>First Name:</strong> {billing_details?.['first-name']}</p>
                    <p><strong>Last Name:</strong> {billing_details?.['last-name']}</p>
                    <p><strong>Phone Number:</strong> {billing_details?.['phone-number']}</p>
                    <p><strong>Street Address:</strong> {billing_details?.['street-address']}</p>
                    <p><strong>Apartment:</strong> {billing_details?.appartment}</p>
                    <p><strong>Customer Notes:</strong> {billing_details?.['customer-notes']}</p>
                </div>
            </div>

            {/* Order Details */}
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Order Details</h4>
                </div>
                <div className="card-body">
                    {/* <p><strong>Order ID:</strong> {order._id.toString().slice(-6)}</p> */}
                    <p><strong>Total Amount:</strong> {total_amount}</p>
                    <p><strong>Status:</strong> {status}</p>
                    <p><strong>Created At:</strong> {new Date(createdAt).toLocaleString()}</p>

                </div>
            </div>

            {/* Ordered Items */}
            <div className="card mb-4">
                <div className="card-header">
                    <h4>Items Ordered</h4>
                </div>
                <div className="card-body">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Discount</th>
                                <th>Quantity</th>
                                <th>Category</th>
                                <th>Images</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order_details?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.price} RS</td>
                                    <td>{item.discount}%</td>
                                    <td>{item.count}</td>
                                    <td>{item.category}</td>
                                    <img src={item?.images?.[0]?.Location} alt={"sa"} style={{ width: '150px', height:"150px", marginRight: '5px' }} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
};

export default OrderDetails;
