import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { get_all_orders } from "../../Configuration/Api";
import { useNavigate } from "react-router-dom";



function AllOrders({ changeView }) {

    const [data, setData] = useState([]);

    const navigate = useNavigate();
    const getData = async () => {
        const res = await get_all_orders("all");
        if (res?.success) {
            setData(res?.data)
        } else {
            Swal.fire({
                icon: "error",
                title: "Something went wrong"
            })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    const goToSingleOrder = (id) => {
        navigate(`?id=${id}`)
        changeView("Single_Order")
    }

    return (
        <div>
            <h3 style={{
                backgroundColor: "grey",
                color: "white",
                textAlign: "center",
                padding: "5px",
                borderRadius: "10px"
            }}>All Orders</h3>
            <table border="" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>View</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className="border"
                        >
                            <td>{index + 1}</td>
                            <td>{item._id.toString().slice(-5)}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td>{item?.billing_details?.["first-name"]}</td>
                            <td>{item?.billing_details?.["phone-number"]}</td>
                            <td>{item?.order_details?.length}</td>
                            <td>{item.status}</td>
                            <td >{item?.total_amount}</td>
                            <td >
                                <p onClick={() => goToSingleOrder(item._id)}
                                    className="p-2 text-center text-white cursor-pointer mt-2" style={{ backgroundColor: "green", cursor: "pointer" }} >
                                    View
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AllOrders