import React from 'react'
import HomeRoute from './HomeRoute'
import { BrowserRouter } from 'react-router-dom'
import Header from '../Components/Header/Header'

function MainRoutes() {
    return (

        <div className="container" >
            <BrowserRouter>
                <Header />
                <HomeRoute />
            </BrowserRouter>
        </div>

    )
}

export default MainRoutes
