import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Dashboard from '../Screen/Dashboard/Dashboard'

function HomeRoute() {
    return (
        <Routes>
            <Route path="/" element={<Dashboard />} />
        </Routes>
    )
}

export default HomeRoute
